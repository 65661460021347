<div class="page-register flex flex-row">
    <div class="left-part w-2/5 flex flex-col justify-between">
        <img class="w-full h-2/3" src="../../../assets/img/login.svg" alt="">
        <div class="left-word text-base font-medium lineheight-150">Unlock
            <span class="left-word-bold"> effortless shopping</span>
            at your own pace
        </div>
    </div>

    <div class="right-part w-3/5 flex flex-col gap-8">
        <div class="flex flex-row justify-between w-full">
            <div></div>
            <p-button icon="pi pi-times" [rounded]="true" severity="secondary" (onClick)="closeWindow()"
            [text]="true"></p-button>
        </div>
        <div class="mt-2" [ngSwitch]="step">
            <ng-container *ngSwitchCase="'loginEmailStep'">
                <ng-content *ngTemplateOutlet="loginEmailStep"></ng-content>
            </ng-container>
            <ng-container *ngSwitchCase="'loginStep'">
                <ng-content *ngTemplateOutlet="loginStep"></ng-content>
            </ng-container>
            <ng-container *ngSwitchCase="'registerStep'">
                <ng-content *ngTemplateOutlet="registerStep"></ng-content>
            </ng-container>
            <ng-container *ngSwitchCase="'resetPasswordEmailStep'">
                <ng-content *ngTemplateOutlet="resetPasswordEmailStep"></ng-content>
            </ng-container>
            <ng-container *ngSwitchCase="'resetPasswordStep'">
                <ng-content *ngTemplateOutlet="resetPasswordStep"></ng-content>
            </ng-container>
            <ng-container *ngSwitchCase="'verifyStep'">
                <ng-content *ngTemplateOutlet="verifyStep"></ng-content>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #loginEmailStep>
    <div class="flex flex-col gap-8">
        <div class="right-title ">Log in or Sign up</div>

        <div class="flex flex-col gap-1">
            <div class="subtitle inputLabel">Email</div>
            <input [ngStyle]="{'width':'100%'}" autofocus pInputText [ngClass]="getEmailClass()"
                [formControl]="getEmailFrnCnt()" (ngModelChange)="setLoginEmail()" />
            <small class="errorInfo" *ngIf="hasEmailError()">{{getEmailErrorInfo()}}
            </small>
        </div>
        <p-button label="Continue with email" (onClick)="checkEmail()" [rounded]="true" [style]="{width:'100%'}"
            [size]="'small'" severity="contrast" [disabled]="emailInValid()"></p-button>

        <p-divider layout="horizontal" [align]="'center'">
            <b>OR</b>
        </p-divider>


        <p-button (onClick)="signInFromGoogle()" [rounded]="true" [style]="{width:'100%'}" [outlined]="true"
            severity="secondary" size="small">
            <div class="flex flex-row items-center justify-center gap-4 w-full">
                <img src="../../../assets/img/google.png" width="17" height="17">
                <div>Continue with Google</div>
            </div>
        </p-button>
        <p-button (onClick)="signInFromFacebook()" [rounded]="true" [style]="{width:'100%'}" [outlined]="true"
            severity="secondary" size="small">
            <div class="flex flex-row items-center gap-4 justify-center w-full">
                <img src="../../../assets/img/Facebook_Logo.png" width="17" height="17">
                <div>Continue with Facebook</div>
            </div>
        </p-button>

        <div class="left-description">By continuing, you agree to AmMall’s Terms & Conditions and Privacy Policy.</div>

    </div>
</ng-template>

<ng-template #loginStep>
    <div class="flex flex-col gap-8 ">
        <div>
            <div class="right-title">Log in</div>
            <div class="mt-2">Welcome back!</div>
        </div>

        <div>
            <div class="subtitle inputLabel">Email</div>
            <div class="flex flex-row gap-1m items-center justify-between">
                <div>{{getLoginEmail()}}</div>
                <p-button label="Change" (onClick)="toNextStep('loginEmailStep')" [text]="true"></p-button>
            </div>
        </div>
        <amm-password [passwordFrmCnt]="getPasswordFrnCnt()"
            [entryConfig]="{label:'Password',noFeedback: true,}"></amm-password>
        <div class="flex flex-row gap-2 items-center justify-between">
            <div class="flex flex-row gap-2 items-center">
                <p-checkbox [(ngModel)]="stayLoggedin" [binary]="true" inputId="binary" />
                <div>Stay logged in</div>
            </div>
            <p-button [style]="{width:'200px'}" label="Forgot password" (onClick)="toNextStep('resetPasswordEmailStep')"
                [text]="true"></p-button>
        </div>

        <p-button label="Login" (onClick)="login()" [rounded]="true" [style]="{width:'100%'}" [size]="'small'"
            severity="contrast"></p-button>
        <p-divider layout="horizontal" [align]="'center'">
            <b>OR</b>
        </p-divider>
        <div class="flex flex-col gap-2">
            <div class="flex flex-col justify-content-end gap-4">
                <p-button (onClick)="signInFromGoogle()" [rounded]="true" [style]="{width:'100%'}" [outlined]="true"
                    severity="secondary" size="small">
                    <div class="flex flex-row items-center gap-4">
                        <img src="../../../assets/img/google.png" width="17" height="17">
                        <div>Continue with Google</div>
                    </div>
                </p-button>
                <p-button (onClick)="signInFromFacebook()" [rounded]="true" [style]="{width:'100%'}" [outlined]="true"
                    severity="secondary" size="small">
                    <div class="flex flex-row items-center gap-4">
                        <img src="../../../assets/img/Facebook_Logo.png" width="17" height="17">
                        <div>Continue with Facebook</div>
                    </div>
                </p-button>
            </div>

        </div>
    </div>
</ng-template>


<ng-template #registerStep>
    <div class="flex flex-col gap-8">
        <div class="right-title">Create a new account</div>

        <div>
            <div class="subtitle inputLabel">Email</div>
            <div class="flex flex-row gap-1m items-center justify-between">
                <div>{{getLoginEmail()}}</div>
                <p-button label="Change" (onClick)="toNextStep('loginEmailStep')" [text]="true"></p-button>
            </div>
        </div>
        <div class="flex flex-col gap-1">
            <div class="subtitle inputLabel">Your name</div>
            <input [ngStyle]="{'width':'100%'}" autofocus pInputText [ngClass]="getNameInputClass()"
                [formControl]="getUserNameFrmCnt()" />
            <small class="errorInfo" *ngIf="hasNameError()">{{getUserNameErrorInfo()}}
            </small>
        </div>
        <amm-password [passwordFrmCnt]="getPasswordFrnCnt()" [entryConfig]="{label:'Password'}"></amm-password>
        <p-button class="button" [style]="{'width':'100%'}" label="Continue" severity="contrast" [rounded]="true"
            (onClick)="sentRegisterEmail()" [disabled]="canNotRegister()">
        </p-button>
    </div>
</ng-template>

<ng-template #resetPasswordEmailStep>
    <div class="flex flex-col gap-8 ">
        <div>
            <div class="right-title">Reset password</div>
            <div>We’ll send a verification code to your email.</div>
        </div>

        <div class="flex flex-col gap-1">
            <div class="subtitle inputLabel">Email</div>
            <input [ngStyle]="{'width':'100%'}" autofocus pInputText [ngClass]="getEmailClass()"
                [formControl]="getEmailFrnCnt()" (ngModelChange)="setResetPasswordEmail()"/>
            <small class="errorInfo" *ngIf="hasEmailError()">{{getResetPasswordEmailErrorInfo()}}
            </small>
        </div>
        <p-button label="Continue" (onClick)="sendResetPasswordEmail()" [rounded]="true" [style]="{width:'100%'}"
            [size]="'small'" severity="contrast" [disabled]="emailInValid()"></p-button>
    </div>
</ng-template>

<ng-template #resetPasswordStep>
    <div class="form flex flex-col gap-8 w-full">
        <div class="right-title">Set a new password</div>
        <div class="flex flex-col gap-3">
            <amm-password [passwordFrmCnt]="getPasswordFrnCnt()" [entryConfig]="{label:'New password'}"></amm-password>
            <amm-password [passwordFrmCnt]="getConfirmPasswordFrnCnt()"
                [entryConfig]="{label:'Re-enter password', noFeedback: true}">
            </amm-password>
            <!-- <amm-password [passwordFrmCnt]="getConfirmPasswordFrnCnt()" [entryConfig]="{label:'Confirm password',error:getComfirmPasswordError(), noFeedback: true,
          errorMsg:'Passwords don\'t match! Make sure the passwords are the same in both fields.'}">
            </amm-password> -->
        </div>
        <p-button class="button" [style]="{'width':'100%'}" label="Start shopping" severity="contrast" [rounded]="true"
            (onClick)="resetPassword()" [disabled]="canBeReset()">
        </p-button>
    </div>
</ng-template>

<ng-template #verifyStep>
    <div class="flex flex-col gap-8">
        <div class="flex flex-col gap-1">
            <div class="right-title">{{getVerifyPageTitle()}}</div>
            <div>We’ve sent a new code to {{getLoginEmail()}}</div>
        </div>
        <div class="flex flex-col gap-1">
            <div class="subtitle inputLabel">Verification code</div>
            <input [ngStyle]="{'width':'100%'}" autofocus pInputText [ngClass]="getCodeClass()" (ngModelChange)="setVerigyCode()"
                [formControl]="getCodeFrnCnt()" [placeholder]="'enter 6-digit code here'" />
            <small class="errorInfo" *ngIf="hasCodeError()">{{getCodeErrorInfo()}}
            </small>
            <p-button AmmCountdown [label]="getResentButtonLabel()" (onClick)="reSendCode()"
                [disabled]="cannotRescentCode()" [text]="true">
            </p-button>
        </div>
        <p-button class="button" [style]="{'width':'100%'}" [label]="getVerifyButtonLabel()" severity="contrast"
            [rounded]="true" (onClick)="varifyCode()" [disabled]="codeInValid()">
        </p-button>
        <div>
            <span>Still have trouble with verification code?</span>
            <span (click)="gotoHelpCenter()">Get help</span>
        </div>
    </div>
</ng-template>
