import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import {
  DialogService,
  DynamicDialogComponent,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { AmmPasswordComponent } from '../../amm-share/components/amm-password/amm-password.component';
import { AmmCountdownDirective } from '../../amm-share/directives/amm-countdown.directive';
import { AmmTemplateNameDirective } from '../../amm-share/directives/amm-templatename.directive';
import { UseraccountModel } from '../../amm-share/models';
import { StorageService } from '../../amm-share/services';
import { AmmDialogService } from '../../amm-share/services/amm-dialog-service.service';
import { GoogleApiService } from '../../amm-share/services/google-api.service';
import { RbacService } from '../../amm-share/services/rbac.service';
import { RoutingService } from '../../amm-share/services/routing.service';
import { HasStringValue, NgFormValidator } from '../../components.global';

@Component({
  selector: 'amm-page-register',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AmmPasswordComponent,
    DividerModule,
    InputTextModule,
    CheckboxModule,
    AmmTemplateNameDirective,
    AmmCountdownDirective,
  ],
  templateUrl: './page-register.component.html',
  styleUrl: './page-register.component.scss',
  providers: [GoogleApiService, DynamicDialogRef],
})
export class PageRegisterComponent implements OnInit {
  @ViewChild(AmmCountdownDirective)
  protected _countdownElm?: AmmCountdownDirective;
  @Input()
  step?: string = REGISTER_STEP.LOGINEMAIL;
  @Output()
  onClose = new EventEmitter<boolean>();

  private _storageService = inject(StorageService);

  constructor(
    private _routingService: RoutingService,
    private _rbacService: RbacService,
    private readonly googleApi: GoogleApiService,
    public ref: DynamicDialogRef<PageRegisterComponent>,
    public dialog: DialogService,
    private ammDialog: AmmDialogService,
  ) {}

  userInfo?: UseraccountModel;

  stayLoggedin: boolean = false;
  currentVarifyStep: string = '';

  ngOnInit(): void {
    // we have to pass the window into the service;
    // each time when we need use googleAPI
    if (typeof window != 'undefined') {
      this.googleApi.initGoogleAPI(window);
      this.googleApi.userProfileSubject.subscribe((info) => {
        this.userInfo = info;
        console.log('google user');
        console.log(this.userInfo);
      });
    }
    if (!this.step) {
      this.step = REGISTER_STEP.LOGINEMAIL;
    }
  }

  resetPassword(): void {
    this._rbacService
      .resetPassword(
        this._emailFrmCnt.value || '',
        this._codeFrmCnt.value || '',
        this._passwordFrmCnt.value || '',
      )
      .subscribe((success) => {
        if (success == null) {
          this._incorrectCode = true;
        } else {
          this._incorrectCode = false;
          this.login();
        }
      });
  }

  private _incorrectCode: boolean = false;

  hasError(): boolean {
    return this._incorrectCode;
  }

  forgetPassword(): void {}

  private _isIncorrectBuyer: boolean = false;
  isIncorrectCode: boolean = false;
  private _passwordFrmCnt: FormControl<string | null> = new FormControl('', [
    NgFormValidator.passwordMustHaveUppercase(
      "Let's make your password stronger! Try including at least one uppercase letter.",
    ),
    NgFormValidator.passwordMustHaveLowercase(
      "Let's make your password stronger! Try including at least one lowercase letter.",
    ),
    NgFormValidator.passwordMustHaveNumber(
      "Let's make your password stronger! Try including at least one numeric.",
    ),
    NgFormValidator.minLength(
      8,
      "Let's make your password stronger! Try including a minimum of 8 characters.",
    ),
  ]);
  private _userNameFrmCnt: FormControl<string | null> = new FormControl('', [
    NgFormValidator.required(),
    NgFormValidator.maxLength(128, 'Your name must less than 128 charactors'),
  ]);
  getUserNameFrmCnt(): FormControl {
    return this._userNameFrmCnt;
  }
  getPasswordFrnCnt(): FormControl {
    return this._passwordFrmCnt;
  }

  private _confirmPasswordFrmCnt: FormControl<string | null> = new FormControl(
    '',
    [
      NgFormValidator.required(),
      NgFormValidator.shouldMatchWith(
        this._passwordFrmCnt,
        "Passwords don't match! Make sure the passwords are the same in both fields.",
      ),
    ],
  );
  getConfirmPasswordFrnCnt(): FormControl {
    return this._confirmPasswordFrmCnt;
  }

  getComfirmPasswordError(): boolean {
    if (!HasStringValue(this._confirmPasswordFrmCnt.value)) {
      return false;
    }
    return this._passwordFrmCnt.value == this._confirmPasswordFrmCnt.value
      ? false
      : true;
  }

  ////////////////////////////share part/////////////////////////////////
  toNextStep(st: string): void {
    this.step = st;
  }

  signInFromFacebook(): void {}

  signInFromGoogle(): void {
    this.googleApi.signin();
  }

  hasEmailError(): boolean {
    return (
      this._isIncorrectBuyer ||
      (this._emailFrmCnt.invalid && HasStringValue(this._emailFrmCnt.value))
    ); //没填的时候不检查。
  }
  emailInValid(): boolean {
    return this._emailFrmCnt.invalid;
  }
  ///////////////////////////1. login email step/////////////////////////
  checkEmail(): void {
    this._rbacService
      .checkEmail(this._emailFrmCnt.value || '')
      .subscribe((has) => {
        console.log(has);
        if (!has.exists) {
          this.step = REGISTER_STEP.REGISTER;
        } else if (has.roles == 'buyer') {
          this.step = REGISTER_STEP.LOGIN;
        } else {
          this._isIncorrectBuyer = true;
        }
      });
  }

  setLoginEmail(): void {
    this._isIncorrectBuyer = false;
  }
  getEmailErrorInfo(): string {
    return this._isIncorrectBuyer
      ? 'waiting the error info from designer'
      : "That doesn't look like a valid email address. Please try again.";
  }

  private _emailFrmCnt: FormControl<string | null> = new FormControl('', [
    NgFormValidator.required(),
    NgFormValidator.email('Not an email address!'),
  ]);

  getEmailFrnCnt(): FormControl {
    return this._emailFrmCnt;
  }

  getLoginEmail(): string {
    return this._emailFrmCnt.value || '';
  }

  getEmailClass(): string {
    return this._isIncorrectBuyer ? 'ng-invalid ng-dirty' : '';
  }

  login(): void {
    this._rbacService
      .login(this._emailFrmCnt.value || '', this._passwordFrmCnt.value || '')
      .subscribe((user) => {
        if (user) {
          this.userInfo = user;
          this._storageService.setLocalItem(
            'buyerInfo',
            JSON.stringify(this.userInfo),
          );
          //TODO: need to close the dialog
          this.ammDialog.loginDialogRef.close();
        }
      });
  }

  instance: DynamicDialogComponent | undefined;
  closeWindow(): void {
    this.ammDialog.loginDialogRef.close();
  }

  getNameInputClass(): string {
    return this._userNameFrmCnt.invalid ? 'ng-invalid ng-dirty' : '';
  }

  //////////////////////////2.2 register step//////////////////////////
  getUserNameErrorInfo(): string {
    if (this._userNameFrmCnt?.errors != null) {
      return this._userNameFrmCnt?.errors['errorMessage'];
    }
    return '';
  }
  hasNameError(): boolean {
    // console.log(this._userNameFrmCnt.valid)
    return this._userNameFrmCnt.invalid || false;
  }

  canNotRegister(): boolean {
    return !(this._userNameFrmCnt.valid && this._passwordFrmCnt.valid);
  }
  sentRegisterEmail(): void {
    this.currentVarifyStep = REGISTER_STEP.REGISTER;
    this.userInfo = {
      nickName: this._userNameFrmCnt.value || '',
      password: this._passwordFrmCnt.value || '',
      loginName: this._emailFrmCnt.value || '',
    };
    // this._merchantService.sendVerifyCode().subscribe();
    this._rbacService.registerStart(this.userInfo).subscribe((hasSend) => {
      console.log(hasSend);
    });
    this.step = REGISTER_STEP.VERIFY; //即使没有发email成功也到此页面，在此页面resend verify code
  }
  getVerifyPageTitle(): string {
    switch (this.currentVarifyStep) {
      case REGISTER_STEP.REGISTER:
        return 'Verify your email';
      case REGISTER_STEP.RESETPASSWORD:
        return 'Reset password';
      default:
        return '';
    }
  }
  getVerifyButtonLabel(): string {
    switch (this.currentVarifyStep) {
      case REGISTER_STEP.REGISTER:
        return 'Start shopping';
      case REGISTER_STEP.RESETPASSWORD:
        return 'Continue';
      default:
        return 'Continue';
    }
  }
  getResentButtonLabel(): string {
    return 'Resend code ' + (this._countdownElm?.getLabel() || '');
  }

  cannotRescentCode(): boolean {
    return !this._countdownElm?.countdownFinish();
  }

  resentFlag = false;
  reSendCode(): void {
    this.resentFlag = true;
    if (this.currentVarifyStep == REGISTER_STEP.REGISTER) {
      this.sentRegisterEmail();
    } else {
      this.sendResetPasswordEmail();
    }
  }
  setVerigyCode(): void {
    this.isIncorrectCode = false;
  }

  varifyCode(): void {
    if (this.currentVarifyStep == REGISTER_STEP.REGISTER) {
      this._rbacService
        .registerComplete(
          this._emailFrmCnt.value || '',
          this._codeFrmCnt.value || '',
        )
        .subscribe((success) => {
          if (success) {
            this.ammDialog.loginDialogRef.close();
          } else {
            this.isIncorrectCode = true;
          }
        });
    } else {
      this._rbacService
        .codeVerify(this._emailFrmCnt.value || '', this._codeFrmCnt.value || '')
        .subscribe((success) => {
          if (success) {
            this.step = REGISTER_STEP.RESETPASSWORD;
          } else {
            this.isIncorrectCode = true;
          }
        });
    }
  }

  gotoHelpCenter(): void {
    this._routingService.gotoUrl('help-center');
  }

  private _codeFrmCnt: FormControl<string | null> = new FormControl('', [
    NgFormValidator.required(),
    NgFormValidator.fixedLengthDigit(6, 'The code is 6 digits'),
    // NgFormValidator.otherError(this.isIncorrectCode, 'Oops! Code doesn\'t match. Check your email for the correct 6-digit verification code and try again.'),
  ]);
  getCodeFrnCnt(): FormControl {
    return this._codeFrmCnt;
  }
  hasCodeError(): boolean {
    return (
      (this._codeFrmCnt.invalid && HasStringValue(this._codeFrmCnt.value)) ||
      this.isIncorrectCode
    ); //没填的时候不检查。
  }
  codeInValid(): boolean {
    return this._codeFrmCnt.invalid || this.isIncorrectCode;
  }

  getCodeErrorInfo(): string {
    if (this._codeFrmCnt?.errors != null) {
      return this._codeFrmCnt?.errors['errorMessage'];
    }
    return "Oops! Code doesn't match. Check your email for the correct 6-digit verification code and try again.";
  }

  getCodeClass(): string {
    return this.isIncorrectCode || this._codeFrmCnt.invalid
      ? 'ng-invalid ng-dirty'
      : '';
  }

  ////////////////////////4. reset password//////////////
  sendResetPasswordEmail(): void {
    //此处新增对输入的邮箱进行验证，是否存在，如果不存在，跳到注册步骤 2024-09-05
    this._rbacService
      .checkEmail(this._emailFrmCnt.value || '')
      .subscribe((has) => {
        if (!has.exists) {
          this.step = REGISTER_STEP.REGISTER;
        } else if (has.roles == 'buyer') {
          this.currentVarifyStep = REGISTER_STEP.RESETPASSWORD;
          this._rbacService
            .emailCode({ loginName: this._emailFrmCnt.value || '' })
            .subscribe((hasSend) => {
              if (hasSend) {
                this.step = REGISTER_STEP.VERIFY;
              } else {
                this._canNotSentEmail = true;
              }
            });
        } else {
          this._isIncorrectBuyer = true;
        }
      });
  }

  canBeReset(): boolean {
    if (
      HasStringValue(this._passwordFrmCnt.value) &&
      this._passwordFrmCnt.value == this._confirmPasswordFrmCnt.value
    ) {
      return false;
    }
    return true;
  }
  setResetPasswordEmail(): void {
    this._canNotSentEmail = false;
  }
  private _canNotSentEmail: boolean = false;
  getResetPasswordEmailErrorInfo(): string {
    return this._canNotSentEmail
      ? 'Loops! Something wrong.'
      : "That doesn't look like a valid email address. Please try again.";
  }
  ///////////////////////new///////////////////
}

const REGISTER_STEP = {
  RESETPASSWORD: 'resetPasswordStep',
  RESETPASSWORDEMAIL: 'resetPasswordEmailStep',
  REGISTER: 'registerStep',
  LOGIN: 'loginStep',
  LOGINEMAIL: 'loginEmailStep',
  VERIFY: 'verifyStep',
  RESENDCODE: 'resendCodeStep',
};
